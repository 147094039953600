var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.detail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_fob_total"),
                prop: "fobTotal",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.fobTotal)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_freight"),
                prop: "freight",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.freight,
                  placeholder: _vm.$t("lbl_type_here"),
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "freight")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_cnf"),
                prop: "cnf",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.cnf)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_ins"),
                prop: "ins",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.ins)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_cif"),
                prop: "cif",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.cif)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_nilai_pabean"),
                prop: "nilaiPabean",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.nilaiPabean)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_bea_masuk"),
                prop: "beaMasuk",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.beaMasuk,
                  placeholder: _vm.$t("lbl_type_here"),
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "beaMasuk")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_ppn"),
                prop: "ppn",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [_vm._v(_vm._s(_vm._f("currency")(_vm.form.ppn)))])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_pph_22"),
                prop: "pph",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [_vm._v(_vm._s(_vm._f("currency")(_vm.form.pph)))])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total"),
                prop: "totalDetail",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalDetail)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }