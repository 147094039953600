import { Api } from "@/models/class/api.class";
import { RequestAccountingTaxCreate, ResponseAccountingTax, ResponseListAccountingTax } from "@interface/accounting-tax.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";
import { IGenericResponsePost } from "@/models/interface-v2/common.interface";

export class AccountingTaxServices extends HttpClient {
  constructor() {
    super();
  }

  public listOfTax(params: RequestQueryParamsModel): Promise<ResponseListAccountingTax> {
    return this.get<ResponseListAccountingTax>(Api.Tax, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public detailTax(id: string): Promise<ResponseAccountingTax> {
    return this.get<ResponseAccountingTax>(`${Api.Tax}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createTax(payload: RequestAccountingTaxCreate): Promise<IGenericResponsePost> {
    return this.post<IGenericResponsePost, RequestAccountingTaxCreate>(Api.Tax, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public updateTax(payload: RequestAccountingTaxCreate, path: string): Promise<ResponseAccountingTax> {
    return this.put<ResponseAccountingTax, RequestAccountingTaxCreate>(`${Api.Tax}/${path}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public deleteTax(path: string): Promise<boolean> {
    return this.delete<boolean>(`${Api.Tax}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const accountingTaxService = new AccountingTaxServices();
