
















































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY, DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { debounceProcess } from "@/helpers/debounce";
import { CURRENCY_CODE } from "@enum/global.enum";
import { ResponsePreference } from "@/models/interface-v2/preferences.interface";
import { PREFERENCE_PURCHASE_KEY } from "@enum/preference.enum";
import MNotificationVue from "@/mixins/MNotification.vue";
import { accountingTaxService } from "@service/accounting-tax.service";
import { IImportCostCreate } from "@/models/interface-v2/import-cost.interface";
import { GR_STATUS } from "@enum/good-receipt.enum";

export default Vue.extend({
  name: "ImportCostDetail",
  mixins: [
    MNotificationVue,
  ],
  data() {
    this.commitState = debounceProcess(this.commitState, 200);
    return {
      CURRENCY_CODE,
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      pphRate: 0,
      ppnRate: 0,
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.importCostStore.form as IImportCostCreate,
      toggle: (st: any) => st.importCostStore.toggleSection as boolean,
      ratePph: (st: any) => st.importCostStore.ratePph,
      ratePpn: (st: any) => st.importCostStore.ratePpn,
      appPreference: (st: any) => st.preferenceStore.appPreference as ResponsePreference[],
    }),
    ...mapGetters({
      getPreferenceValue: "preferenceStore/GET_PREFERENCE_VALUE",
      getGRStatus: "importCostStore/GET_STATUS_GOOD_RECEIPT",
    }),
    isWaitForApproval(): boolean {
      return this.getGRStatus === GR_STATUS.WAIT_FOR_APPROVAL;
    },
    isSubmitted(): boolean {
      return this.getGRStatus === GR_STATUS.SUBMITTED;
    },
    isUnbilled(): boolean {
      return this.getGRStatus === GR_STATUS.UNBILLED;
    },
  },
  watch: {
    "appPreference.length": {
      immediate: true,
      handler: "onChangePreference"
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    ...mapMutations({
      setForm: "importCostStore/SET_FORM",
      setRatePph: "importCostStore/SET_RATE_PPH",
      setRatePpn: "importCostStore/SET_RATE_PPN",
    }),
    ...mapActions({
      recalculateAll: "importCostStore/RECALCULATE_ALL",
      calcPph: "importCostStore/CALC_PPH",
      calcPpn: "importCostStore/CALC_PPN",
    }),
    commitState(value, field): void {
      this.setForm({
        ...this.form,
        [field]: value,
      });

      this.recalculateAll();
    },
    onChangePreference(newValue: number): void {
      if (!newValue) return;
      const pph: ResponsePreference = this.getPreferenceValue(PREFERENCE_PURCHASE_KEY.IMPORT_COST_INCOME_TAX);
      const ppn: ResponsePreference = this.getPreferenceValue(PREFERENCE_PURCHASE_KEY.IMPORT_COST_VAT_TAX);
      if (pph) {
        this.getPPhRate(pph.value);
      }
      if (ppn) {
        this.getPPnRate(ppn.value);
      }
    },
    async getPPhRate(id: string): Promise<void> {
      try {
        if (!id) return;
        const { rate } = await accountingTaxService.detailTax(id);
        this.setRatePph(rate);
        this.recalculateAll();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
        console.error(error);
      }
    },
    async getPPnRate(id: string): Promise<void> {
      try {
        if (!id) return;
        const { rate } = await accountingTaxService.detailTax(id);
        this.setRatePpn(rate);
        this.recalculateAll();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
        console.error(error);
      }
    },
  }
});
